const environment = {
    API_ENDPOINT: "https://api.reachdev.biz/api",
    PARTNER_ENDPOINT: "https://business.reachdev.biz",
    CLIENT_ENDPOINT: "https://booking.reachdev.biz",
    firebaseConfig: {
        apiKey: "AIzaSyDpyOU4LwGtOj-tvPHs_LPO-WRgSNQVfIk",
        authDomain: "reach-services-network-dev.firebaseapp.com",
        projectId: "reach-services-network-dev",
        storageBucket: "reach-services-network-dev.appspot.com",
        messagingSenderId: "952908320310",
        appId: "1:952908320310:web:f0883ed990c8b958631297",
        measurementId: "G-XLQ6G2F244"
    },
}
export default environment;